var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"approvalsListing"},[_c('b-overlay',{attrs:{"show":_vm.approvalStatus,"rounded":"md"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"6","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label font-weight-bolder"}},[_vm._v("Loading...")])],1)]},proxy:true}])},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"align-middle"},[_vm._v("Name")]),_c('th',{staticClass:"align-middle"},[_vm._v("Role")]),_c('th',{staticClass:"align-middle"},[_vm._v("Comments")]),_c('th',{staticClass:"align-middle"},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.approversList),function(approver,approverIndex){return _c('tr',{key:approverIndex},[_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approver.institution_user.first_name)+_vm._s(approver.institution_user.last_name || "")+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approver.role)+" ")]),_c('td',{staticClass:"align-middle"},[(
                    _vm.applicationStatus[approver.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id]
                        .comments != null
                  )?_c('span',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(_vm.applicationStatus[approver.id][_vm.currentApplication.id] .comments)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Comments!")])]),_c('td',{staticClass:"align-middle"},[(
                    _vm.applicationStatus[approver.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id]
                        .approval_status == 'approved'
                  )?_c('span',{staticClass:"text-success"},[_vm._v("Approved")]):(
                    _vm.applicationStatus[approver.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id]
                        .approval_status == 'rejected'
                  )?_c('span',{staticClass:"text-danger"},[_vm._v("Rejected")]):(
                    _vm.applicationStatus[approver.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id]
                        .approval_status == 'indirect_approved'
                  )?_c('span',{staticClass:"text-success"},[_vm._v("Indirect Approved")]):(
                    _vm.applicationStatus[approver.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id] &&
                      _vm.applicationStatus[approver.id][_vm.currentApplication.id]
                        .approval_status == 'resubmission'
                  )?_c('span',{staticClass:"text-info"},[_vm._v("Resubmission")]):_c('span',{staticClass:"text-warning"},[_vm._v("Pending")])])])}),0)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }