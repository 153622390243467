<template>
  <div>
    <div class="approvalsListing">
      <b-overlay :show="approvalStatus" rounded="md">
        <template #overlay>
          <div class="text-center">
            <b-icon icon="stopwatch" font-scale="6" animation="cylon"></b-icon>
            <p id="cancel-label font-weight-bolder">Loading...</p>
          </div>
        </template>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark">
              <tr>
                <th class="align-middle">Name</th>
                <th class="align-middle">Role</th>
                <th class="align-middle">Comments</th>
                <th class="align-middle">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(approver, approverIndex) in approversList"
                :key="approverIndex"
              >
                <td class="align-middle">
                  {{ approver.institution_user.first_name
                  }}{{ approver.institution_user.last_name || "" }}
                </td>
                <td class="align-middle">
                  {{ approver.role }}
                </td>
                <td class="align-middle">
                  <span
                    class="text-dark"
                    v-if="
                      applicationStatus[approver.id] &&
                        applicationStatus[approver.id][currentApplication.id] &&
                        applicationStatus[approver.id][currentApplication.id]
                          .comments != null
                    "
                  >
                    {{
                      applicationStatus[approver.id][currentApplication.id]
                        .comments
                    }}
                  </span>
                  <span v-else class="text-danger">No Comments!</span>
                </td>
                <td class="align-middle">
                  <span
                    class="text-success"
                    v-if="
                      applicationStatus[approver.id] &&
                        applicationStatus[approver.id][currentApplication.id] &&
                        applicationStatus[approver.id][currentApplication.id]
                          .approval_status == 'approved'
                    "
                    >Approved</span
                  >
                  <span
                    class="text-danger"
                    v-else-if="
                      applicationStatus[approver.id] &&
                        applicationStatus[approver.id][currentApplication.id] &&
                        applicationStatus[approver.id][currentApplication.id]
                          .approval_status == 'rejected'
                    "
                    >Rejected</span
                  >
                  <span
                    class="text-success"
                    v-else-if="
                      applicationStatus[approver.id] &&
                        applicationStatus[approver.id][currentApplication.id] &&
                        applicationStatus[approver.id][currentApplication.id]
                          .approval_status == 'indirect_approved'
                    "
                    >Indirect Approved</span
                  >
                  <span
                    class="text-info"
                    v-else-if="
                      applicationStatus[approver.id] &&
                        applicationStatus[approver.id][currentApplication.id] &&
                        applicationStatus[approver.id][currentApplication.id]
                          .approval_status == 'resubmission'
                    "
                    >Resubmission</span
                  >

                  <span class="text-warning" v-else>Pending</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentApplication", "surveyApproval", "approversList"],
  data() {
    return {
      applicationStatus: {},
      approvalStatus: false,
    };
  },

  methods: {
    async getApplicationApprovalStatus() {
      this.approvalStatus = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.currentApplication.id}/onlineregapprovals`;
      await this.$axios
        .get(url)
        .then((response) => {
          let appStatus = response.data;
          appStatus.forEach((status) => {
            this.$set(this.applicationStatus, status.approver_id, {});
            this.$set(
              this.applicationStatus[status.approver_id],
              status.application_id,
              {
                approval_status: status.approval_status,
                comments: status.comments,
              }
            );
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.approvalStatus = false;
    },
  },
};
</script>
