<template>
  <div>
    <div class="approvalForms">
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <div class="loading" v-if="fetchData">
              <load-data></load-data>
            </div>
            <div v-else>
              <h6 class="text-center mb-2 mt-2" style="font-size: 20px; font-weight: 900">
                Approval Forms for:
                <span v-if="surveyApproval.name" class="text-info">{{
                  surveyApproval.name
                }}</span>
              </h6>

              <PendingForms1
                :formsDetails="formsDetails"
                :approvalQuestions="approvalQuestions"
                @ApplicationUpdated="getApplicationsListsToApproveOrReject()"
                @pendingForms="pendingForms"
                :surveyApproval="surveyApproval"
                :approversList="approversList"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import PendingForms1 from "./PendingForms1.vue";
export default {
  async created() {
    // await this.getApplicationsListsToApproveOrReject();
    await this.getSurveyApproval();
    await this.getApprovers();
    await this.getApprovalQuestions();
  },
  components: {
    PendingForms1,
  },
  data() {
    return {
      fetchData: false,
      numberOfPendingForms: 0,
      numberOfApprovedForms: 0,
      formsDetails: {
        onlineRegApplications: [],
        onlineApprovers: [],
        onlineRegDetails: {},
        onlineRegParticipants: [],
      },
      approvalQuestions: [],
      surveyApproval: {},
      approversList: [],
    };
  },
  methods: {
    async getSurveyApproval() {
      const approvalId = this.$route.params.approval_id;
      const url = this.$store.getters.getAPIKey.surveyApprovals + `/${approvalId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.surveyApproval = {
            id: response.data.id,
            name: response.data.name,
          };

          response;
        })
        .catch((error) => {
          this.loadApproval = false;
          error;
        });
    },
    async getApprovers() {
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.surveyApproval.id}/onlineregapprovers`;
      this.$axios
        .get(url)
        .then((response) => {
          this.approversList = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },
    pendingForms(value) {
      this.numberOfPendingForms = value;
    },
    totalForms(value) {
      this.numberOfApprovedForms = value;
    },
    async getApplicationsListsToApproveOrReject() {
      this.fetchData = await true;
      const eventId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${eventId}/onlineregapplications`;
      await this.$axios
        .get(url)
        .then((response) => {
          // this.$set(this.formsDetails, "onlineRegApplications", []);
          this.$set(this.formsDetails, "onlineApprovers", []);
          this.$set(this.formsDetails, "onlineRegDetails", []);
          this.$set(this.formsDetails, "onlineRegParticipants", []);

          // this.formsDetails.onlineRegApplications =
          //   response.data.onlineregapplications;
          this.formsDetails.onlineApprovers = response.data.onlineregapprovers;
          this.formsDetails.onlineRegDetails = response.data.onlineregistration;
          this.formsDetails.onlineRegParticipants = response.data.onlineregparticipants;
          this.checkNumberOfPendingAndApprovedForms();
          // this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },

    async getApprovalQuestions() {
      const approvalId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${approvalId}/onlineregquestions`;
      await this.$axios.get(url).then((response) => {
        this.approvalQuestions = [];
        let questions = response.data;

        questions.forEach((ques) => {
          this.approvalQuestions.push({
            id: ques.id,
            question_no: ques.question_no,
            name: ques.name,
            summary: ques.summary,
            answers: ques.answers,
            studentAnswer: null,
            studentAttachments: [],
            studentName: "",
            participantId: "",
          });
        });
      });
    },
  },
};
</script>
