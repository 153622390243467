<template>
  <div>
    <div class="pendingForms">
      <b-overlay
        :show="approveParticipant"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <b-row v-else>
          <b-col md="12" class="text-center">
            <div class="closeBtn mb-2 text-right">
              <p @click="closeCurrentTab()" class="btn m-0">
                <i class="fas fa-window-close fa-4x text-danger"></i>
              </p>
            </div>
            <h6 style="font-size: 14px; font-weight: 600">User Roles:</h6>

            <p
              style="font-size: 10px; font-weight: 600"
              class="p-0 m-0 mb-2"
              v-if="selectedApprover == null"
            >
              Please Select a role
            </p>

            <div class="selectApprover text-center">
              <b-form-select
                :options="onlineApproversOptions"
                v-model="selectedApprover"
                class="w-50"
                @change="getApproverParticipants"
              ></b-form-select>
            </div>
            <br />
          </b-col>
          <b-col md="3" v-if="selectedApprover != null">
            <b-card-group deck>
              <b-card header="Participants">
                <b-list-group>
                  <b-list-group-item v-if="participants.length == 0"
                    >No Participants Found!</b-list-group-item
                  >
                  <b-list-group-item
                    v-else
                    class="btn text-left mt-2 border border-info align-middle"
                    v-for="(participant, participantIndex) in participants"
                    :key="participantIndex"
                    @click="
                      getParicipantApplication(participant.id, participantIndex)
                    "
                    :active="
                      participantIndex == activeParticipant ? true : false
                    "
                  >
                    <p style="font-size: 12px; font-weight: 600">
                      {{
                        participant.roll_number == null
                          ? "RN"
                          : participant.roll_number
                      }}) {{ participant.first_name
                      }}{{ participant.last_name || "" }}
                    </p>
                    <div>
                      <span
                        style="font-size: 12px; font-weight: 600"
                        v-for="(application, applicationIndex) in applications[
                          participant.id
                        ]"
                        :key="applicationIndex"
                        :class="
                          activeParticipant == participantIndex
                            ? 'text-white'
                            : 'text-info'
                        "
                      >
                        <span v-if="application.status == 'ready'">
                          {{
                            convertTimeToLocal(application.starts_at).format(
                              "DD/MM/YYYY hh:mm a"
                            )
                          }}
                          -
                          {{ application.description }}
                          <span
                            :class="
                              application.status == 'ready'
                                ? 'text-success'
                                : 'text-danger' && ' ml-1 '
                            "
                            ><i class="fas fa-circle"></i
                          ></span>
                          <br
                        /></span>
                      </span>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-card-group>
          </b-col>

          <b-col md="9">
            <br />
            <div v-if="currentParticipant.applications.length != 0">
              <div class="applicationForms d-flex flex-wrap">
                <b-button
                  size="sm"
                  :variant="
                    application.status == 'ready'
                      ? activeApplication == applicationIndex
                        ? 'light'
                        : 'success'
                      : activeApplication == applicationIndex
                      ? 'light'
                      : 'danger'
                  "
                  v-for="(application,
                  applicationIndex) in currentParticipant.applications"
                  :key="applicationIndex"
                  @click="
                    getApplicationDetails(
                      application.id,
                      application.status,
                      applicationIndex,
                      application.description
                    )
                  "
                >
                  <span class="d-flex align-items-center justify-content-center"
                    >Form {{ applicationIndex + 1 }}
                    <span
                      :class="
                        application.status == 'ready'
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      <i class="fas fa-circle"></i></span
                  ></span>

                  {{
                    convertTimeToLocal(application.starts_at).format(
                      "DD/MM/YYYY hh:mm a"
                    )
                  }}
                </b-button>
              </div>

              <div
                class="participantApplication p-3 border border-info rounded mt-2 bg-white"
                v-if="currentApplication.id != null"
              >
                <b-form
                  @submit.prevent="prepareApplicationToApproveOrReject()"
                  class="border-0"
                >
                  <div class="print mb-2 text-right">
                    <b-button variant="info" size="sm" @click="printPdf()"
                      >Print</b-button
                    >
                  </div>
                  <div>
                    <p
                      style="font-size: 16px; font-weight: 600; text-align: center"
                      class="text-center bg-success text-white rounded p-1"
                    >
                      Name:
                      {{ participantsObject[currentParticipant.id].first_name
                      }}{{
                        participantsObject[currentParticipant.id].last_name ||
                          ""
                      }}
                    </p>
                  </div>

                  <br />
                  <div class="d-flex justify-content-between flex-wrap">
                    <div
                      class="application mb-3 w-50"
                      style="font-size: 14px; font-weight: 600"
                      v-for="(question,
                      questionIndex) in participantApplicationSheet"
                      :key="questionIndex"
                    >
                      <div class="question mb-2">
                        {{ question.question_no }}) {{ question.name }}
                      </div>
                      <div class="answer mb-2">
                        <span
                          :class="
                            question.studentAnswer == 'null'
                              ? 'text-danger'
                              : ''
                          "
                          >{{
                            question.studentAnswer == "null"
                              ? "No Text Answer!"
                              : question.studentAnswer
                          }}</span
                        >
                      </div>
                      <div
                        class="attachments mb-2"
                        v-if="question.studentAttachments.length != 0"
                      >
                        <h6 style="font-size: 14px; font-weight: 600">
                          Attachments:
                        </h6>
                        <span
                          class="text-dark ml-3"
                          v-for="(file,
                          fileIndex) in question.studentAttachments"
                          :key="fileIndex"
                        >
                          <a
                            :href="
                              userAttachments + file.file_path + file.file_name
                            "
                            target="_blank"
                            class="text-decoration-none"
                            style="font-size: 12px; font-weight: 600"
                            >{{ file.file_name }}</a
                          >
                        </span>
                      </div>
                    </div>
                  </div>

                  <div v-if="currentApplication.status == 'ready'">
                    <div class="comments mb-2">
                      <b-form-input
                        placeholder="Enter Comments"
                        v-model="approval.comments"
                      ></b-form-input>
                    </div>
                    <div class="approveOrReject d-flex justify-content-between">
                      <b-button
                        variant="warning"
                        type="submit"
                        size="sm"
                        @click="approval.type = 'resubmission'"
                        class="text-white"
                        >Ask to Resubmit</b-button
                      >
                      <b-button
                        variant="primary"
                        type="submit"
                        size="sm"
                        @click="approval.type = 'approved'"
                        >Approve</b-button
                      >
                    </div>
                  </div>
                  <div
                    class="alert alert-info mb-1 text-center font-weight-bolder"
                    v-if="currentParticipant.description != null"
                  >
                    {{ currentParticipant.description }}
                  </div>
                </b-form>
                <div class="approvals mt-2">
                  <ApprovalsList
                    ref="getApprovals"
                    :currentApplication="currentApplication"
                    :surveyApproval="surveyApproval"
                    :approversList="approversList"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="selectedApprover != null">
                <p
                  class="text-info text-center"
                  v-if="activeParticipant == null"
                >
                  Please Select an application
                </p>
                <p class="text-danger text-center" v-else>
                  Application not submitted!
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApprovalsList from "./ApprovalsList.vue";
export default {
  props: [
    "formsDetails",
    "approvalQuestions",
    "surveyApproval",
    "approversList",
  ],
  async created() {
    this.fetchData = true;

    await this.getCurrentUserApprovers();
    this.userAttachments = await this.$store.getters.getAPIKey.userAttachments;
    this.fetchData = false;
    // this.prepareApproversOptions();
  },
  components: {
    ApprovalsList,
  },
  data() {
    return {
      today: moment(),
      approveParticipant: false,
      fetchData: false,
      loadApplication: false,
      onlineApproversOptions: [],
      selectedApprover: null,
      participants: [],
      activeParticipant: null,
      applications: [],
      currentParticipant: {
        applications: [],
        id: null,
      },
      currentApplication: {
        id: null,
        status: null,
        description: null,
      },
      participantsObject: {},
      participantApplicationSheet: [],
      approval: {},
      activeApplication: null,
    };
  },
  methods: {
    async printPdf() {
      this.approveParticipant = true;
      let url;

      url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.currentApplication.id}/report/pdfexport`;

      // const downloadedObject = null;

      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          // downloadedObject = response.data;
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(url, "_blank").print();

          response;
        })
        .catch((error) => {
          error;
        });
      this.approveParticipant = false;
    },
    convertTimeToLocal(theTime) {
      let localTime = moment(
        moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      return localTime;
    },
    async getCurrentUserApprovers() {
      const approvalId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/${approvalId}/institutionuser/onlineregapprovers`;
      let approvers = [];
      await this.$axios
        .get(url)
        .then((response) => {
          approvers = response.data;
          this.onlineApproversOptions = [
            { value: null, text: "Select Approver", disabeld: false },
          ];
          approvers.forEach((approver) => {
            this.onlineApproversOptions.push({
              value: approver.id,
              text: approver.role + " (" + approver.order + ")",
            });
          });
        })
        .catch((error) => {
          error;
        });

      if (approvers.length == 1) {
        this.selectedApprover = await this.onlineApproversOptions[1].value;
        await this.getApproverParticipants();
      }
    },
    async getApproverParticipants() {
      this.fetchData = true;
      this.currentParticipant.id = null;
      this.currentParticipant.applications = [];
      this.activeParticipant = null;
      this.currentApplication.id = null;
      this.currentApplication.status = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapprovers/${this.selectedApprover}/applicationsforapprove`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.participants = response.data.participants;
          this.applications = response.data.participantsApplications;
          this.participants.forEach((participant) => {
            this.$set(this.participantsObject, participant.id, participant);
          });
          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async getParicipantApplication(participantId, participantIndex) {
      this.currentParticipant.id = null;
      this.currentParticipant.applications = [];
      this.activeParticipant = null;
      this.currentApplication.id = null;
      this.currentApplication.status = null;
      this.approval = {};
      this.activeParticipant = participantIndex;
      this.currentParticipant.id = participantId;

      this.currentParticipant.applications = this.applications[participantId];

      this.currentParticipant.applications.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.starts_at) - new Date(a.starts_at);
      });

      this.activeApplication = null;

      this.getApplicationDetails(
        this.currentParticipant.applications[0].id,
        this.currentParticipant.applications[0].status,
        0,
        this.currentParticipant.applications[0].description
      );
    },
    async getApplicationDetails(
      applicationId,
      applicationStatus,
      activeIndex,
      description
    ) {
      this.approval = {};
      this.participantApplicationSheet = await this.approvalQuestions;

      this.approveParticipant = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${applicationId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          const answers = response.data.answers;
          let answesObjects = {};
          answers.forEach((ans) => {
            answesObjects[ans.question_id] = ans;
          });
          this.participantApplicationSheet.forEach((ques, quesIndex) => {
            if (answesObjects[ques.id]) {
              this.participantApplicationSheet[quesIndex].studentAnswer =
                answesObjects[ques.id].answer == null
                  ? null
                  : answesObjects[ques.id].answer;
              this.participantApplicationSheet[
                quesIndex
              ].studentAttachments = answesObjects[ques.id].files
                ? answesObjects[ques.id].files
                : [];
            } else {
              this.participantApplicationSheet[quesIndex].studentAnswer = null;
              this.participantApplicationSheet[
                quesIndex
              ].studentAttachments = [];
            }
          });
          this.currentApplication.id = applicationId;
          this.currentApplication.status = applicationStatus;
          this.currentParticipant.description = description;
          this.activeApplication = activeIndex;

          response;
        })
        .catch((error) => {
          error;
        });
      this.approveParticipant = false;
      await this.$refs.getApprovals;
      await this.$refs.getApprovals.getApplicationApprovalStatus();
    },

    async prepareApplicationToApproveOrReject() {
      if (this.approval.type == "resubmission") {
        if (confirm(`You are going to request resubmission`) != true) return;
      }
      const approvalId = this.$route.params.approval_id;
      this.approveParticipant = true;
      await this.approval;
      let postData = await {
        approver_id: this.selectedApprover,
        comments: this.approval.comments,
        approval_status: this.approval.type,
        participant_id: this.currentParticipant.id,
        onlineregistration_id: approvalId,
        onlineregapplication_id: this.currentApplication.id,
      };

      const applicationStatus = await this.approveOrRejectApplication(postData);
      if (applicationStatus) {
        this.$toast.success(`Application ${this.approval.type}`, {
          position: "top",
          duration: 3000,
        });

        await this.getApproverParticipants();
      }
      if (!applicationStatus) {
        this.$toast.error("Something Went Wrong, please try again!", {
          position: "top",
          duration: 3000,
        });
      }
      this.approveParticipant = false;
    },

    async approveOrRejectApplication(postData) {
      let status = null;
      const url = this.$store.getters.getAPIKey.mainAPI + `/onlineregapprovals`;
      await this.$axios
        .post(url, postData)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
  },
};
</script>

<style >
.pendingForms {
  min-height: 100vh;
}
.pendingForms .col-md-3:not(:first-child),
.pendingForms .col-md-9 {
  height: 600px;
  overflow-y: scroll;
}
.pendingForms .col-md-3 .card .card-header {
  font-size: 18px;
  font-weight: 600;
}
.pendingForms i {
  font-size: 10px !important;
}
.pendingForms .closeBtn i {
  font-size: 20px !important;
}
.pendingForms .applicationForms button {
  width: 150px;
  margin-left: 10px;
}

.pendingForms .applicationForms button:not(:first-child) {
  margin-left: 10px;
}
</style>
